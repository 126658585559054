window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.init = false;

jQuery(function ($) {

	"use strict";

  new ModalVideo('.js-modal-video', { autoplay: true, channel: 'youtube'});

	// Adjust Position

	$.fn.kkAdjustImagePositioning = function (callback) {
		$(this).each(function () {
			var this_ = $(this),
			wdt = this_.width(),
			hgt = this_.height();

			this_.find('img, video').css({
				width : '',
				height : ''
			}).each(function () {
				var elem = $(this),
				r_w = hgt / wdt,
				i_w = elem.width(),
				i_h = elem.height(),
				r_i = i_h / i_w,
				new_w,
				new_h,
				new_left,
				new_top;

				if (elem.hasClass('kk-fit-image')) {
					if (r_w > r_i) {
						new_h = wdt * r_i;
						new_w = wdt;
					} else {
						new_h = hgt;
						new_w = hgt / r_i;
					}
				} else {
					if (r_w > r_i) {
						new_h = hgt;
						new_w = hgt / r_i;
					} else {
						new_h = wdt * r_i;
						new_w = wdt;
					}
				}

				elem.css({
					width : new_w,
					height : new_h,
					left : (wdt - new_w) / 2,
					top : (hgt - new_h) / 2
				});

				if (typeof callback === 'function') {
					callback();
				}
			});
		});
	};

	// Animated logo

	if ( $(window).width() > 1025 ) {

		var logo = $('.page-template-template-homepage-php .site-logo-wrapper'),
		menu = $('.page-template-template-homepage-php .main-nav > ul').children('li'),
		content = $('.content, .container'),
		logoHolder = $('.page-template-template-homepage-php .menu-wrapper');

		logo.transition({
			x : 0
		}, 400);

		menu.each(function (index) {
			$(this).delay(150 * index).transition({
				x : 0
			}, 400);
		});

		setTimeout(
			function () {
			$('body').addClass('kk-show-content');
		}, 300);


		// Animated Page Change


		function redirect(url) {
			window.location = url;
		}

		(function () {
			var siteUrl = document.location.origin;

			$(document).on( "click", "a[href^='" + siteUrl + "']:not([href*='/wp-admin/']):not([href*='/wp-content/']):not([href*='/wp-login.php']):not([href$='/feed/']):not([href*='/#']):not([href*='/?']):not([target='_blank'])", function (event) {
				event.preventDefault();

				var linkLocation = this.href;

				$('body').removeClass('kk-show-content');

				if ($(logoHolder).length) {
					logoHolder.transition({
						top : '25px'
					}, 300, redirect(linkLocation));
				} else {
					setTimeout(function () {
						redirect(linkLocation);
					}, 400);
				}
			});
		})();
	}

	//Portfolio filtering

	if ($().isotope) {
		$(function () {
			var isotopeContainer = $('.isotope-container'),
			isotopeFilter = $('#filter'),
			isotopeLink = isotopeFilter.find('a');
			isotopeContainer.isotope({
				itemSelector : '.isotope-item',
				layoutMode : 'fitRows',
				filter : '.all',
				percentPosition: true,
				transitionDuration: 800,
				fitRows: {
					columnWidth: '.isotope-item',
					horizontalOrder: true,
				},
			});

			isotopeLink.on( 'click', function () {
				var selector = $(this).attr('data-category');
				isotopeContainer.isotope({
					filter : '.' + selector,
					itemSelector : '.isotope-item',
					layoutMode : 'fitRows',
					animationEngine : 'best-available'
				});
				isotopeLink.removeClass('active');
				$(this).addClass('active');
				return false;
			});

		});
	}

	//Masonry

	if ( $().masonry && typeof kktfwpGalleryData !== "undefined" ) {
		var $container = $('.gallery-wrapper');
			$container.masonry({
				itemSelector : '.gallery-thumb-wrapper'
			});
	}

	//Search

	(function () {
		var $seach_string = kktfwpSearch.string;

		$('#s').each(
			function () {
			if ($(this).val() === '' || $(this).val() === kktfwpSearch.string ) {
				$(this).val( kktfwpSearch.string );
				$(this).blur(
					function () {
					if ($(this).val() === '') {
						$(this).val( kktfwpSearch.string );
					}
				});

				$(this).focus(
					function () {
					if ($(this).val() === '' || $(this).val() === kktfwpSearch.string ) {
						$(this).val('');
					}
				});
			}
		});
	})();

	// Tabs

	$('.tabs').tabs({
		fx : {
			opacity : 'show'
		}
	});

	// Background video
	// @ since ver 1.8

	// Background video
/*
	if (typeof kk_js_video !== "undefined") {

		var muted = true;

		if ( kk_js_video.muted !== '1' ) {
			muted = false;
		}

		if (typeof kk_js_video.helper == "undefined") {

			var kkHTML5Video = '<video class="kk-html5-bg" id="kk-html5-bg" loop autoplay="autoplay" ' + ( ( muted ) ? 'muted' : '') + '><source src="' + kk_js_video.video_source + '" type="video/mp4" >Your browser does not support the video tag.</video>';

			$('.video-bg #player').append(kkHTML5Video);

			var videoBg = document.getElementById('kk-html5-bg');

			var checkLoad = function () {
				if ( videoBg.readyState == 4 ) {

					$(videoBg).css({
						opacity : 1
					});

					videoBg.play();

					if('objectFit' in document.documentElement.style === false) {
						$('.video-bg').kkAdjustImagePositioning();

						$(window).on( 'resize', function() {
							$('.video-bg').kkAdjustImagePositioning();
						})
					}
				} else {
					setTimeout(checkLoad, 100);
				}
			}
			checkLoad();

		} else {
			$('.video-bg #player').kkBgVideo({
				videoId : kk_js_video.video_source,
				mute : muted,
				repeat : true
			});
		}
	}
*/
	// Owl Carousel
	if ($().owlCarousel) {
		$(".owl-carousel").owlCarousel({
			items: 1,
			loop: true,
			nav: true,
			dots: false,
			autoHeight: true,
			smartSpeed: 1000,
			autoplay: ( typeof kktfwpPortfolioSlider != 'undefined' ) ? parseInt(kktfwpPortfolioSlider.autoplay) : false,
			autoplayTimeout: ( typeof kktfwpPortfolioSlider != 'undefined' ) ? parseInt(kktfwpPortfolioSlider.delay) : 3000,

		});
	}

	// Mobile menu

	$('ul.kktfwp-mtree').mtree({
		collapsed: true, // Start with collapsed menu (only level 1 items visible)
		close_same_level: true, // Close elements on same level when opening new node.
		duration: 600, // Animation duration should be tweaked according to easing.
		listAnim: true, // Animate separate list items on open/close element (velocity.js only).
		easing: 'easeOutQuart', // Velocity.js only, defaults to 'swing' with jquery animation.
	});

	$('body').on( 'click touch', '.kktfwp-mobile-burger', function () {
		$('body').toggleClass('menu-is-opened');
	});

	// Scroll To Top

	$('.totop').on('click', function () {
		$.scrollTo(0, 800, {
			queue : true
		});
	});

	// Tipsy

	$(".social a").tipsy({
		gravity : 'n',
		fade : false
	});

	// Background audio
	function bgAudioInit() {
		$( '#audioBG' ).jPlayer({
					ready : function () {
						$(this).jPlayer('setMedia', {
							mp3 : kk_js_audio.file,
						}).jPlayer( 'play' );
					},
					ended : function (event) {
							$(this).jPlayer( 'play' );
					},
					swfPath : kk_js_audio.path
				});

		$('#pause_bt').on( 'click', function() {
			$('#audioBG').jPlayer('pause');
			$(this).hide();
			$('#play_bt').show();
		});

		$('#play_bt').on( 'click', function() {
			$('#audioBG').jPlayer('play');
			$(this).hide();
			$('#pause_bt').show();
		});

	}

	if ( typeof kk_js_audio !== 'undefined' ) {
		bgAudioInit();
	}


	// Touch support for fullscreen gallery
	// @since ver. 2.0

	if (typeof Hammer !== "undefined") {
		var hammerElement = document.getElementById('supersized');
		var kkMc = new Hammer(hammerElement);

		kkMc.on('swipeleft tap swiperight', function (ev) {
			if (ev.type == 'swipeleft') {
				api.nextSlide();
			} else if (ev.type == 'swiperight') {
				api.prevSlide();
			}
		});
	}

	// Background slider

	function backgroundInit() {
		$.supersized({
			progress_bar : 1, // Timer for each slide
			slideshow : 1, // Slideshow on/off
			autoplay : 1, // Slideshow starts playing automatically
			mouse_scrub : 0,
			image_protect : 0,
			slide_links : 'blank', // Individual links for each slide (Options: false, 'number', 'name', 'blank')
			start_slide : parseInt(kktfwpSliderData.startSlide), //Start slide (0 is random)
			slide_interval :  parseInt(kktfwpSliderData.interval),
			transition : parseInt(kktfwpSliderData.effect),
			transition_speed : parseInt(kktfwpSliderData.speed),
			keyboard_nav : false,
			performance :  2,
			slide_counter : 0,
			slide_captions : 0,
			source : (kktfwpSliderData.flickrState !== '0') ? 2 : 0,
			user : kktfwpSliderData.flickrID,
			total_slides : parseInt(kktfwpSliderData.totalSlides),
			image_size : kktfwpSliderData.imgSize,
			api_key : kktfwpSliderData.flickrAPI,
			slides : (kktfwpSliderData.flickrState !== 0) ? kktfwpSliderData.slides : []
		});
	}

	if ( typeof kktfwpSliderData !== 'undefined' && ( !! kktfwpSliderData.slides && kktfwpSliderData.sliderState === '1' || kktfwpSliderData.flickrState === '1' ) ) {
		backgroundInit();
	}

	// Fullscreen Gallery
	function fullscreenGalleryInit() {
		$.supersized({
			progress_bar : 1,
			image_protect : 0,
			thumbnail_navigation : 1,
			fit_always : ( !! kktfwpFullscreenGallery.scaleMode ) ? parseInt(kktfwpFullscreenGallery.scaleMode)  : 0,
			start_slide : 1 ,
			transition : ( !! kktfwpFullscreenGallery.transition ) ? parseInt(kktfwpFullscreenGallery.transition)  : 1,
			transition_speed : 1000,
			slide_interval : 4000,
			autoplay : 0,
			mouse_scrub : 0,
			slide_links : 'number', // Individual links for each slide (Options: false, 'number', 'name', 'blank')
			keyboard_nav : false,
			performance :  2,
			slides : kktfwpFullscreenGallery.slides
		});
	}

	if ( typeof kktfwpFullscreenGallery !== 'undefined' && !! kktfwpFullscreenGallery.slides ) {
		fullscreenGalleryInit();
	}

	$(window).on( 'load', function () {
		$('#supersized-loader').delay(500).transition({
			bottom : -200
		}, 300, function () {
			$('#supersized-loader').remove();
		});
	});

	// WordPress Gallery Images
	(function () {
		var $filterImg = function( el ) {
				el.each(function () {
					var $img = $('img', this);

					if (/\.(jpg|png|gif)$/.test( $img.parents('a').attr('href') ) ) {
						$img.parents('a').addClass('kk-lightbox');
					}
				});
			}

		if ( $('figure.gallery-item').length ) {
			$filterImg($('.gallery-item'));
		}
		if ( $('.blocks-gallery-item').length ) {
			$filterImg($('.blocks-gallery-item figure'));
		}
	})();

	// Lighbox Settings

	function lightGalleryInit( element ) {
		$( 'body' ).lightGallery(
			{
			selector: element,
			mode: kktfwpLighboxData.transition,
			addClass: 'kktfwp-theme',
			speed: 1000,
			thumbnail: parseInt( kktfwpLighboxData.thumbs),
			showThumbByDefault: parseInt( kktfwpLighboxData.thumbsByDefault),
			autoplayControls: parseInt( kktfwpLighboxData.autoplay),
			fullScreen: parseInt( kktfwpLighboxData.fullscreen),
			zoom: parseInt( kktfwpLighboxData.zoom),
			download: parseInt( kktfwpLighboxData.download),
			share: false,
			backdropDuration: 400,
			hash: false,
			thumbWidth: 90,
			thumbContHeight: 90,
			thumbMargin: 10,
			videoMaxWidth: '1000px',
			autoplayFirstVideo: false,
			youtubeThumbSize: 'sddefault'
			}
		).on('onBeforeSlide.lg',function(event, index, fromTouch, fromThumb){
			$('.lg-sub-html').transition({ opacity: 0 }, 300)
		}).on('onAfterSlide.lg',function(event, index, fromTouch, fromThumb){
			$('.lg-sub-html').transition({ opacity: 1 }, 300)
		});
	};

	if ( typeof kktfwpLighboxData !== 'undefined' && kktfwpLighboxData.state === '1' ) {
		lightGalleryInit( '.kk-lightbox');
	}

	// Init lazyload

	lazySizesConfig.lazyClass = 'responsively-lazy-img';
	lazySizesConfig.loadedClass = 'responsively-loaded';
	lazySizesConfig.expand = 1;
	lazySizesConfig.loadMode = 2;
	lazySizes.init();

	$(document).on('lazybeforeunveil', function(e){
		//use width of parent node instead of the image width itself
		e.detail.width = $(e.target).parent().innerWidth() || e.detail.width;
	}).on('lazyloaded', function(e){
		$(e.target).parent().addClass('spiner-off');
	});

	// Set mobile header bg
	if ( $(window).width() < 1000 ) {
	(function() {
		var $bg = $('.content, .container').css('background-color');
		$('.menu-wrapper, .kktfwp-footer').css( 'background-color', $bg );

		$(window).on( 'resize', function() {
			if ( $(window).width() > 1000 ) {
				$('.menu-wrapper, .kktfwp-footer').css( 'background-color', '' );
			}
		});
	})()
	}

});
