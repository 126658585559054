// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

import $ from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

import ModalVideo from 'modal-video';
window.ModalVideo = ModalVideo;

require('jquery-validation');
require('../plugins/1_jquery-migrate.min');
require('../plugins/2_scripts');
require('../plugins/3_jquery.transit.min');
require('../plugins/4_jquery.mtree');
require('../plugins/5_lightgallery-all.min');
//require('../plugins/6_jquery.bgVideo');
require('../plugins/7_jquery.supersized');
require('../plugins/8_jquery.supersized.shutter.min');
require('../plugins/9_jquery.tipsy');
require('../plugins/10_core.min');
require('../plugins/11_widget.min');
require('jquery-tabs/jquery.tabs');
require('../plugins/13_jquery.custom');
require('../plugins/14_lazysizes.min');
require('imagesloaded/imagesloaded.pkgd.min');
require('../plugins/masonry.min');
require('../plugins/15_wp-embed.min');
require('../plugins/16_custom_video');

$(document).ready(function() {
  $('form.wpcf7-form').validate({
    errorElement: "span"
  });

  $(".calendar-navigator-wrapper").on("click", "img", function(){
    var $target = $($(this).data("target")).find(".right-side");

    console.log($target);

    if ($(this).hasClass("next")) {
      $target.animate({
        scrollLeft: "+=200px"
      }, "slow");
    } else {
      $target.animate({
        scrollLeft: "-=200px"
      }, "slow");
    }
  });
})
