/*jslint browser: true */ /*global window, $ */
(function(){
var ident, ID = 'kk_self_js_video';
var CH = window.CH = window.CH || {};
CH.ident = CH.ident || {};
				
ident = CH.ident[ID] = {
	'settings': function(){
		
		return {
		};
		
	},
	'videoMarkup': function(d){
		var d;
		
		return '<video style="width: 100%; height: 100%; object-fit: cover;" playsinline autoplay loop ' + (d.muted ? 'muted' : '') + ' ' + (d.controls ? 'controls' : '') + '><source src="' + d.source_primary + '" type="' + kk_self_js_video.video_source_primary_mime + '"><source src="' + d.source_fallback + '" type="' + kk_self_js_video.video_source_fallback_mime + '"></video>';
	
	},
	'setup': function(){
		
		var h = '<div id="kk-bgvideo-container_" class="video-bg" style="overflow: hidden; position: fixed; width: 100%; height: 100%;"><div id="kk-bgvideo-player_" style="width: 100%; height: 100%; position: relative">' + ident.videoMarkup({source_primary: kk_self_js_video.video_source_primary, source_fallback: kk_self_js_video.video_source_fallback, muted: true, controls: false}) + '</div></div>';
		
		ident.items.body.prepend(h).find('div.open-ful-video a.js-modal-video').on('click', function(){
			
			//console.log($(this).attr('data-video-id'))
			/*
			var h = '<div class="modal-video" tabindex="-1" role="dialog" aria-label="You just openned the modal video" id="K8AFTIDHH2AKU"><div class="modal-video-body"><div class="modal-video-inner"><div class="modal-video-movie-wrap" style="padding-bottom:56.25%"><button class="modal-video-close-btn js-modal-video-dismiss-btn" aria-label="Close the modal by clicking here"></button><div id="kk-bgvideo-container_" class="video-bg" style="overflow: hidden; position: fixed; width: 100%; height: 100%;"><div id="kk-bgvideo-player_" style="width: 100%; height: 100%; position: relative">' + ident.videoMarkup({source_primary: kk_self_js_video.video_source_primary, source_fallback: kk_self_js_video.video_source_fallback, muted: false, controls: true}) + '</div></div></div></div></div></div>';
	   
	   		ident.items.body.append(h).find('div.modal-video button.modal-video-close-btn').on('click', function(){
				
				ident.items.body.find('div.modal-video').remove();
				
			});
			*/
		});
		
		var video = ident.items.body.find('div#kk-bgvideo-container_ video');
		if(typeof video.loop == 'boolean'){
			video.loop = true;
		}
		else{
			video.on('ended', function(){
				this.currentTime = 0;
				this.play();
			}, false);
		}
		
		return false;
		
	},
	'init': function(){
		
		ident.items.body = $('body');
		
		if(typeof kk_self_js_video !== 'undefined'){
			return ident.setup();
		}
		else{
			console.log('kk_self_js_video is undefined!');
			return false;
		}
		
	},
	'items' : {}
};

$(ident.init);
})();
//# sourceURL=/js/kk_self_js_video.js